import {RecommendProduct} from "@/Api/Shop/index";
import Storage from "@/util/Storage"
import {CommoditySucStore} from "@/views/Shop/CommoditySuc/Store/index";

// 获取评论
export function getComment(){
    const userId = Storage.GetData_ && Storage.GetData_("userId")
    const token = Storage.GetData_ && Storage.GetData_("token")
    return Promise.resolve( RecommendProduct({ userId,token },CommoditySucStore.getCommodityUpData).then(res=>{
        return res.data
    }) )
}

