

import { Module,VuexModule,getModule,Mutation,Action } from "vuex-module-decorators"
import store from "@/store/index"
import CommoditySucStoreType from "@/views/Shop/CommoditySuc/Store/indexType";
import {RecommendProductType} from "@/Api/Shop/interfaceType";

@Module({
    name:"CommoditySuc",
    store,
    dynamic: true,
})
export default class CommoditySuc extends VuexModule implements CommoditySucStoreType{
    CommodityUpData: RecommendProductType ={
        pageNo:1,
        pageSize:15,
        id:1,
        storeId:""
    };
    get getCommodityUpData(){ return this.CommodityUpData }
    @Mutation
    SetCommodityUpData(data: RecommendProductType): void {
        this.CommodityUpData = data
    }

    CommodityList: any[] = [];
    get getCommodityList(){ return this.CommodityList }
    @Mutation
    SetCommodityList(data: any): void {
        this.CommodityList = data
    }

}

export const CommoditySucStore = getModule( CommoditySuc )
